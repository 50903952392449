/* global document */
import Quill from 'quill';
import naja from 'naja';
import 'quill/dist/quill.snow.css';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const toolbarOptions = [
	[{ header: [1, 2, 3, 4, 5, 6, false] }],
	['bold', 'underline'],
	[{ align: [] }],
	['link'],
	[{ list: 'ordered' }, { list: 'bullet' }],
	['clean'],
];

const loadWysiwygs = (root) => {
	const wysiwygEls = root.querySelectorAll('.js-wysiwyg');

	if (wysiwygEls.length > 0) {
		wysiwygEls.forEach((wysiwygEl) => {
			const el = wysiwygEl;
			const editorEl = document.createElement('div');
			el.parentNode.appendChild(editorEl);

			const editor = new Quill(editorEl, {
				theme: 'snow',
				modules: {
					clipboard: true,
					toolbar: toolbarOptions,
				},
			});

			editor.setContents(editor.clipboard.convert(el.value), 'silent');
			editorEl.classList.add('border');
			el.classList.add('visually-hidden');

			editor.on('text-change', () => {
				el.value = editor.root.innerHTML;
			});
		});
	}
};

loadWysiwygs(document);

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	loadWysiwygs(snippet);
});
