/* global document, Event */
import naja from 'naja';
import { Datepicker } from 'vanillajs-datepicker/js/main';

const options = {
	buttonClass: 'btn',
	format: 'yyyy-mm-dd',
};

function triggerChange(e) {
	e.currentTarget.dispatchEvent(new Event('change', { bubbles: true }));
	e.currentTarget.dispatchEvent(new Event('input', { bubbles: true }));
}

function initDatePicker(el) {
	const dp = new Datepicker(el, options);
	el.addEventListener('changeDate', triggerChange);
	return dp;
}

const datepickers = [].slice.call(document.querySelectorAll('[data-datepicker]'));
datepickers.map(initDatePicker);

naja.snippetHandler.addEventListener('beforeUpdate', (event) => {
	const { snippet } = event.detail;
	const datepickersSnippets = [].slice.call(snippet.querySelectorAll('[data-datepicker]'));
	datepickersSnippets.map((el) => {
		if (el.datepicker) {
			el.datepicker.destroy();
		}

		return null;
	});
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	const datepickersSnippets = [].slice.call(snippet.querySelectorAll('[data-datepicker]'));
	datepickersSnippets.map(initDatePicker);
});
