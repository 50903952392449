/* global document, window */
import { Tab } from 'bootstrap';

const tabEl = document.querySelector('button[data-bs-toggle="tab"]');
if (tabEl) {
	document.addEventListener('shown.bs.tab', (event) => {
		window.history.pushState(null, null, event.target.getAttribute('data-bs-target'));
	});

	if (window.location.hash) {
		const loadTabTriggerEl = document.querySelector(`${window.location.hash}-tab`);
		if (loadTabTriggerEl) {
			const tab = new Tab(loadTabTriggerEl);
			tab.show();
		}
	}
}
