/* global document */
import naja from 'naja';
import { Tooltip } from 'bootstrap';

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

naja.snippetHandler.addEventListener('beforeUpdate', (event) => {
	const { snippet } = event.detail;
	const tooltipTriggerListSnippets = [].slice.call(snippet.querySelectorAll('[data-bs-toggle="tooltip"]'));
	Array.prototype.forEach.call(tooltipTriggerListSnippets, (tooltipTriggerEl) => {
		const tooltipObject = Tooltip.getInstance(tooltipTriggerEl);
		if (tooltipObject) {
			tooltipObject.dispose();
		}
	});
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	const { snippet } = event.detail;
	const tooltipTriggerListSnippets = [].slice.call(snippet.querySelectorAll('[data-bs-toggle="tooltip"]'));
	tooltipTriggerListSnippets.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
});
