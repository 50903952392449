/* global document */
const checkAllItems = () => {
	const toggle = document.querySelector('.js-check-all-toggle');
	if (toggle) {
		let isAllChecked = true;
		document.querySelectorAll('.js-check-all-item').forEach((el) => {
			if (!el.checked) {
				isAllChecked = false;
			}
		});
		toggle.checked = isAllChecked;
	}
};

document.addEventListener('change', (event) => {
	const { target } = event;
	if (target.classList.contains('js-check-all-toggle')) {
		document.querySelectorAll('.js-check-all-item').forEach((el) => {
			const item = el;
			item.checked = target.checked;
		});
	} else if (target.classList.contains('js-check-all-item')) {
		checkAllItems();
	}
});

document.addEventListener('DOMContentLoaded', () => checkAllItems());
