/* global document */
import { Toast } from 'bootstrap';

export default class BootstrapToastExtension {
	initialize(naja) {
		this.naja = naja;

		const toasts = document.querySelectorAll('.toast');
		Array.prototype.forEach.call(toasts, (toast) => {
			BootstrapToastExtension.initializeToast(toast);
		});

		naja.snippetHandler.addEventListener('afterUpdate', (event) => {
			const snippetToasts = event.detail.snippet.querySelectorAll('.toast');
			Array.prototype.forEach.call(snippetToasts, (toast) => {
				BootstrapToastExtension.initializeToast(toast);
			});
		});

		document.addEventListener('hidden.bs.toast', (event) => {
			if (!event.target.classList.contains('toast')) {
				return;
			}

			event.target.remove();
		});
	}

	static initializeToast(toast) {
		let toastObject = Toast.getInstance(toast);
		if (!toastObject) {
			toastObject = new Toast(toast);
			toastObject.show();
		}
		return toastObject;
	}
}
