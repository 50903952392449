/* global document */
import naja from 'naja';

document.addEventListener('input', (event) => {
	if (!event.target.classList.contains('js-autosubmit')) {
		return;
	}

	const form = event.target.closest('form');
	if (form) {
		naja.uiHandler.submitForm(form);
	}
});

document.addEventListener('input', (event) => {
	if (!event.target.classList.contains('js-container-autosubmit-input')) {
		return;
	}

	const container = event.target.closest('.js-container-autosubmit');
	const submitButton = container.querySelector('.js-container-autosubmit-submitButton');
	if (submitButton) {
		submitButton.click();
	}
});

document.addEventListener('change', (event) => {
	const { target } = event;
	if (!target.classList.contains('js-filter-input')) {
		return;
	}

	target.parentNode.querySelector('.js-filter-check').checked = target.value.length > 0;
});
