/* global document */
document.addEventListener('change', (event) => {
	const { target } = event;
	const form = target.closest('form.js-form-change');
	if (form) {
		form.querySelectorAll('.js-form-change-button').forEach((el) => {
			const item = el;
			item.disabled = false;
		});
	}

	const tr = target.closest('tr.js-tr-inputs-change');
	if (tr) {
		tr.querySelectorAll('.js-form-change-button').forEach((el) => {
			const item = el;
			item.disabled = false;
		});
	}
});
