/* global document, window */
import naja from 'naja';

document.addEventListener('input', (event) => {
	if (
		!event.target.classList.contains('js-autosubmit-debounce')
		&& !event.target.classList.contains('js-container-autosubmit-input-debounce')
	) {
		return;
	}

	const isContainer = event.target.classList.contains('js-container-autosubmit-input-debounce');
	const element = event.target;
	if (element.dataset.autosubmitDebounceId) {
		window.clearTimeout(element.dataset.autosubmitDebounceId);
	}

	element.dataset.autosubmitDebounceId = window.setTimeout(() => {
		delete element.dataset.autosubmitDebounceId;
		if (isContainer) {
			const container = element.closest('.js-container-autosubmit');
			const submitButton = container.querySelector('.js-container-autosubmit-submitButton');
			if (submitButton) {
				submitButton.click();
			}
		} else {
			const form = event.target.closest('form');
			if (form) {
				naja.uiHandler.submitForm(form);
			}
		}
	}, 2000);
});
